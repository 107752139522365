import React from "react";
import ReactDOMClient from "react-dom/client";
// import { Teaser as Teaser2 } from "./screens/Teaser2";
// import { Teaser as Teaser3 } from "./screens/Teaser3";
import {
 createBrowserRouter,
 RouterProvider,
} from "react-router-dom";

import "./style.css";

export const Teaser1 = () => {
  return (
    <div className="teaser">
      <div className="div">
        <div className="frame">
          <img className="vector" alt="Vector" src="/img/vector.svg" />
        </div>
        <div className="overlap-group-wrapper">
          <div className="overlap-group overlap-group1">
            <div className="rectangle" />
            <h1 className="text-wrapper">Interessiert an einem digitalen Charity Projekt?</h1>
          </div>
        </div>
        <img className="vector-1" alt="Vector" src="/img/vector-4.svg" />
        <img className="vector-2" alt="Vector" src="/img/vector-3.svg" />
        <img className="vector-3" alt="Vector" src="/img/vector-2.svg" />
        <img className="vector-4" alt="Vector" src="/img/vector-1.svg" />
        <div className="text-wrapper-2">DREAM FUNDER</div>
      </div>
    </div>
  );
};

export const Teaser2 = () => {
  return (
    <div className="teaser">
      <div className="div">
        <div className="frame">
          <img className="vector" alt="Vector" src="/img/vector.svg" />
        </div>
        <div className="overlap-group-wrapper">
          <div className="overlap-group overlap-group2">
            <div className="rectangle" />
            <h1 className="text-wrapper text-wrapper2">Einem Projekt welches Träumer und Spender verbindet</h1>
          </div>
        </div>
        <img className="vector-1" alt="Vector" src="/img/vector-4.svg" />
        <img className="vector-2" alt="Vector" src="/img/vector-3.svg" />
        <img className="vector-3" alt="Vector" src="/img/vector-2.svg" />
        <img className="vector-4" alt="Vector" src="/img/vector-1.svg" />
        <div className="text-wrapper-2">DREAM FUNDER</div>
      </div>
    </div>
  );
};
export const Teaser3 = () => {
  return (
    <div className="teaser">
      <div className="div">
        <div className="overlap">
          <div className="frameS3">
            <a className="groupS3" href="tel:+41763255457">
              <img className="vectorS3" alt="Vector" src="/img/phone.svg" />
            </a>
            <a className="groupS3" href="mailto:severin.dheureuse@gmail.com">
              <img className="sms" alt="Sms" src="/img/sms.png" />
            </a>
          </div>
        </div>
        <div className="overlap-group-wrapper">
          <div className="overlap-group overlap-group3">
            <div className="rectangle" />
            <h1 className="text-wrapper text-wrapper2">Dann gib uns die Chance unser Konzept vorzustellen</h1>
          </div>
        </div>
        <img className="vector-1" alt="Vector" src="/img/vector-4.svg" />
        <img className="vector-2" alt="Vector" src="/img/vector-3.svg" />
        <img className="vector-3" alt="Vector" src="/img/vector-2.svg" />
        <img className="vector-4" alt="Vector" src="/img/vector-1.svg" />
        <div className="text-wrapper-2">DREAM FUNDER</div>
      </div>
    </div>
  );
};

const router = createBrowserRouter([
 {
   path: "/",
   element: <a href={`/1`}><Teaser1 /></a>,
 },
 {
  path: "/1",
  element: <a href={`/2`}><Teaser2 /></a>,
 },
 {
  path: "/2",
  element: <Teaser3 />,
 },
]);

ReactDOMClient.createRoot(app).render(
 <React.StrictMode>
   <RouterProvider router={router} />
 </React.StrictMode>
);